import React from "react";
import Post from "../../components/post";

export default function Post11() {
  return (
    <Post i={10}>
      <p>My post</p>
    </Post>
  );
}
